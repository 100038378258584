import { FC, useEffect, useReducer, useRef } from 'react';

export const GOOGLE_CLIENT_ID =
  '653843838606-0hnj22i0hh9ps68nlas6u3sktube3gbd.apps.googleusercontent.com';

declare const google: any;

interface WindoWithGoogleLib extends Window {
  onGoogleLibraryLoad: () => void;
}

declare let window: WindoWithGoogleLib;

interface Props {
  disabled?: boolean;
  onLogin: (response: { credential: string }) => void;
}

const GoogleLoginButton: FC<Props> = ({ onLogin, disabled }) => {
  const buttonContainerRef = useRef(null);
  const onLoginPropsRef = useRef(onLogin);

  const [forceRerenderKey, forceRerender] = useReducer((num) => num + 1, 0);

  useEffect(() => {
    window.onGoogleLibraryLoad = () => {
      forceRerender();
    };
  }, []);

  useEffect(() => {
    if (typeof google === 'object') {
      google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        context: 'signin',
        ux_mode: 'popup',
        auto_select: false,
        callback: onLoginPropsRef.current,
      });
      google.accounts.id.renderButton(buttonContainerRef.current, {
        type: 'standard',
        size: 'large',
        width: 350,
      });
    }
  }, [forceRerenderKey]);
  return (
    <div className="d-flex justify-content-center">
      <div
        ref={buttonContainerRef}
        style={{
          width: 'fix-content',
          height: 44,
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.7 : 1,
        }}
      ></div>
    </div>
  );
};

export default GoogleLoginButton;
