import moment from 'moment';
import { FC, useState, useEffect } from 'react';
import { Modal, InputGroup, Accordion, FormControl } from 'react-bootstrap';
import userApi from '../../../api/userApi';
import { User } from '../../../types/User';
import { UserChangeLog, UserChangeTypeId } from '../../../types/UserChangeLog';
import { formatMinutes } from '../../../utils/time';

const DiffArea = (
  arr: Array<{ __old: string | number; __new: string | number; key: string }>,
  isTimesheetUpdate: boolean,
  title: string,
) => (
  <>
    {title}
    <div
      className="bg-light200 radius-sm p-2 mt-2 no-scrollbars"
      style={{ overflow: 'scroll', maxWidth: 520 }}
    >
      <pre className="p-0 m-0 text-dark200">
        {'{\n'}
        {arr.map((dif, i) => (
          <div className="ms-2" style={{ marginTop: i ? 5 : 0 }} key={dif.key}>
            <p className="my-0 ">
              {dif.key}:{' '}
              <span
                style={{
                  padding: '0 3px',
                  textDecoration: 'line-through',
                  backgroundColor: 'rgba(228, 86, 76, 0.4)',
                }}
              >
                {isTimesheetUpdate
                  ? formatMinutes(dif.__old as number)
                  : dif.__old}
              </span>{' '}
              <span
                style={{
                  padding: '0 3px',
                  backgroundColor: 'rgba(59, 219, 38, 0.4)',
                }}
              >
                {isTimesheetUpdate
                  ? formatMinutes(dif.__new as number)
                  : dif.__new}
              </span>
              ,
            </p>
          </div>
        ))}
        {'}'}
      </pre>
    </div>
  </>
);

interface Props {
  user: User;
  visible: boolean;
  onVisibleChange: (val: boolean) => void;
}

const ChangeLogModal: FC<Props> = ({ user, visible, onVisibleChange }) => {
  const [allLogs, updateAllLogs] = useState<UserChangeLog[]>([]);
  const [logs, updateLogs] = useState<UserChangeLog[]>([]);
  const [searchVal, updateSearch] = useState('');
  const [openChange, updateOpenChange] = useState('');

  useEffect(() => {
    if (visible) {
      const fetchLogs = async () => {
        try {
          const res = await userApi.getChangeLog(user.id);
          updateLogs(res.data.logs);
          updateAllLogs(res.data.logs);
        } catch {
          // eslint-disable-next-line
          console.error('Fetch failed');
        }
      };
      fetchLogs();
    }
  }, [visible]);

  useEffect(() => {
    if (searchVal.length <= 3) {
      updateLogs(allLogs);
    } else {
      updateLogs(
        allLogs.filter((it) => {
          const difVals = Object.values(it.diff || {});
          const keys = Object.keys(it.diff || {});

          const val = searchVal.toLowerCase();

          if (
            difVals.some(
              (it) =>
                (typeof it.__new === 'string' &&
                  it.__new.toLowerCase().includes(val)) ||
                (typeof it.__old === 'string' &&
                  it.__old.toLowerCase().includes(val)),
            )
          ) {
            return true;
          } else if (moment(it.createdAt).format('DD.MM.YYYY').includes(val)) {
            return true;
          } else if (it.editor.includes(val)) {
            return true;
          } else if (keys.some((it) => it.includes(val))) {
            return true;
          } else {
            return false;
          }
        }),
      );
    }
  }, [searchVal]);

  return (
    <Modal show={visible} onHide={() => onVisibleChange(false)} size="lg">
      <Modal.Header
        closeButton={true}
        className="d-flex justify-content-between"
      >
        <Modal.Title className="flex-grow-1">
          {user.firstName} employee logins
        </Modal.Title>
        <InputGroup className="me-2" style={{ maxWidth: 200 }}>
          <FormControl
            value={searchVal}
            onChange={(e) => updateSearch(e.target.value)}
          />
        </InputGroup>
      </Modal.Header>

      <Modal.Body>
        <span>{user.firstName} Change log</span>
        <Accordion
          className="mt-3"
          activeKey={openChange}
          onSelect={(key) => updateOpenChange(key as string)}
        >
          {logs.map((l) => {
            const arr = Object.entries(l.diff || {}).map(([key, value]) => ({
              key,
              ...value,
            }));
            const isTimesheetUpdate =
              // eslint-disable-next-line
              // @ts-ignore
              !!l.diff?.weeklyWorkMinutes ||
              [
                UserChangeTypeId.TIMESHEET_BALANCE_AUTO_UPDATE,
                UserChangeTypeId.TIMESHEET_BALANCE_DIRECT_UPDATE,
              ].includes(l.typeId);
            return (
              <Accordion.Item eventKey={l.id} key={l.id}>
                <Accordion.Header className="d-flex justify-content-between">
                  <span className="flex-grow-1">
                    {l.type.titleEn} <small>({l.editor})</small>
                  </span>
                  <span className="flex-grow-0 me-2">
                    {moment(l.createdAt).format('lll')}
                  </span>
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column">
                  {DiffArea(arr, isTimesheetUpdate, l.type.titleEn)}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeLogModal;
