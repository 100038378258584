export enum CompanyPermissionId {
  CORE = 1,
  TIME_TRACKING = 2,
  ABSENCES = 3,
  WELLBEING = 4,
  PROJECTS = 5,
  SHIFTS = 7,
}

export interface CompanyPermission {
  id: CompanyPermissionId;
  title: string;
}

export enum EmployeePermissionId {
  PERSONAL_SETTINGS = 1,
  COMPANY_SETTINGS = 2,
}

export interface EmployeePermission {
  id: EmployeePermissionId;
  title: string;
}
