import { debounce } from 'lodash';
import { FC, useContext, useState, useEffect, useCallback } from 'react';
import {
  Container,
  Form,
  Col,
  InputGroup,
  ListGroup,
  Spinner,
  Row,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { FaSearch, FaLock, FaTrash } from 'react-icons/fa';
import companyApi from '../../api/companyApi';
import { AppContext } from '../../context';
import { Company } from '../../types/Company';
import { CompanyPermissionId } from '../../types/Permissions';
import CompanyCard from './SubComponents/CompanyCard';
import DepartmentsCard from './SubComponents/DepartmentsCard';

export interface LocalCompany extends Company {
  selectedPermissions: CompanyPermissionId[];
}

const Companies: FC = () => {
  const context = useContext(AppContext);
  const [companies, updateCompanies] = useState<Company[]>([]);
  const [selectedCompany, updateSelectedCompany] = useState<LocalCompany>(null);
  const [activeView, updateActiveView] = useState('company');
  const [query, updateQuery] = useState('');
  const [loading, toggleLoading] = useState(false);
  const [hidePaywallCompanies, setPaywall] = useState(true);

  const searchCompanies = async (q?: string) => {
    try {
      toggleLoading(true);
      const { data } = await companyApi.searchCompanies(
        q || query,
        hidePaywallCompanies,
      );
      updateCompanies(data);
      if (data.length) {
        const company =
          data.find((d) => d.id === selectedCompany?.id) || data[0];
        updateSelectedCompany({
          ...company,
          selectedPermissions: company.permissions.map((p) => p.id),
        });
      }
      toggleLoading(false);
    } catch {
      toggleLoading(false);
      context.showToast({
        title: 'Fetch failed',
        message: 'Company fetch failed',
        variant: 'danger',
      });
    }
  };

  useEffect(() => {
    searchCompanies();
  }, [hidePaywallCompanies]);

  const debounceSearch = useCallback(
    debounce((newValue) => searchCompanies(newValue), 700),
    [],
  );

  return (
    <Container>
      <Row>
        <Col md={4} className="pt-3">
          <InputGroup>
            <InputGroup.Text>
              {loading ? (
                <Spinner size="sm" animation="border" />
              ) : (
                <FaSearch />
              )}
            </InputGroup.Text>
            <Form.Control
              value={query}
              onChange={(e) => {
                updateQuery(e.target.value);
                debounceSearch(e.target.value);
              }}
              placeholder="Search by company name"
            />
          </InputGroup>
          <div className="d-flex align-items-center mt-2">
            <Form.Check
              label="Hide paywall and deleted companies"
              className="me-2"
              checked={hidePaywallCompanies}
              onChange={() => setPaywall(!hidePaywallCompanies)}
            />
            <FaLock />
          </div>

          <ListGroup
            onSelect={(k) => {
              const company: Company = companies.find((c) => c.id === k);
              updateSelectedCompany({
                ...company,
                selectedPermissions: company.permissions.map((p) => p.id),
              });
            }}
            activeKey={selectedCompany?.id}
            as="ul"
            style={{ maxHeight: 'calc(100vh - 130px) ' }}
            className="mt-2 overflow-scroll border-top"
          >
            {companies.map((c) => (
              <ListGroup.Item
                className={`fs-6 ${c.deletedOn && 'text-danger'}`}
                action={true}
                eventKey={c.id}
                as="button"
                key={c.id}
              >
                {c.deletedOn ? (
                  <FaTrash className="me-2" />
                ) : c.paywallActive ? (
                  <FaLock className="me-2" />
                ) : null}
                {c.title}
                {c.businessId ? ` / ${c.businessId}` : ''}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>

        <Col md={8} className="pt-3">
          <Tabs
            onSelect={(val) => updateActiveView(val)}
            activeKey={activeView}
          >
            <Tab title="Company" eventKey={'company'} />
            <Tab title="Departments (OLD)" eventKey={'departments'} />
          </Tabs>
          {selectedCompany &&
            (activeView === 'company' ? (
              <CompanyCard
                selectedCompany={selectedCompany}
                onSubmit={searchCompanies}
                updateSelectedCompany={updateSelectedCompany}
              />
            ) : activeView === 'departments' ? (
              <DepartmentsCard companyId={selectedCompany.id} />
            ) : null)}
        </Col>
      </Row>
    </Container>
  );
};

export default Companies;
