import axios from 'axios';

import workantStorage, { getLoginData } from '../utils/localstorage';

const agent = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

agent.interceptors.request.use((config) => {
  const loginData = getLoginData();
  if (loginData) {
    config.headers['x-auth-token'] = loginData.token;
    config.headers['x-auth-email'] = loginData.email;
  }

  return config;
});

agent.interceptors.response.use(
  (r) => r,
  async (e) => {
    if (e.response.status === 403) {
      workantStorage.removeItem('localUser');
      workantStorage.removeItem('loginData');
      try {
        await fetch(process.env.REACT_APP_API_URL, { method: 'GET' });
      } catch {
        // eslint-disable-next-line no-console
        console.log('Logout failed');
      }
      window.location.reload();
    }
    return Promise.reject(e);
  },
);

export default agent;
