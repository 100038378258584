import moment from 'moment';

export const getHoursFromMinutes = (minutes: number) => {
  const duration = moment.duration(minutes, 'minutes');
  const days = duration.get('days');
  const hours = duration.get('hours');
  const totalHours = days * 24 + hours;
  const hourString = totalHours.toString();

  return hourString.length === (hourString[0] === '-' ? 2 : 1)
    ? hourString[0] === '-'
      ? `${hourString[0]}0${hourString[1]}`
      : `0${hourString}`
    : totalHours;
};

export const getMinutesFromMinutes = (minutes: number) => {
  const duration = moment.duration(minutes, 'minutes');
  const mins = duration.get('minutes');
  const plussed = mins < 0 ? mins * -1 : mins;
  return plussed.toString().length === 1 ? `0${plussed}` : plussed;
};

export const formatMinutes = (minutes: number) => {
  const isMinus = minutes < 0;
  const actualMinutes = isMinus ? -minutes : minutes;
  const totalHours = Math.floor(actualMinutes / 60);
  const mins = actualMinutes - totalHours * 60;

  return `${isMinus ? '-' : ''}${totalHours}h ${mins}min`;
};

export const dateInputFormat = 'YYYY-MM-DD';
export const monthInputFormat = 'YYYY-MM';
