import moment from 'moment';
import { FC, useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import userApi from '../../../api/userApi';
import { User } from '../../../types/User';
import { TimesheetAuditLog } from '../../../types/UserChangeLog';
import { formatMinutes } from '../../../utils/time';

interface Props {
  user: User;
  visible: boolean;
  onVisibleChange: (val: boolean) => void;
}

const ChangeLogModal: FC<Props> = ({ user, visible, onVisibleChange }) => {
  const [logs, updateLogs] = useState<TimesheetAuditLog[]>([]);

  useEffect(() => {
    if (visible) {
      const fetchLogs = async () => {
        try {
          const res = await userApi.getTimesheetLogs(user.id);
          updateLogs(res.data.logs);
        } catch {
          // eslint-disable-next-line
          console.error('Fetch failed');
        }
      };
      fetchLogs();
    }
  }, [visible]);

  return (
    <Modal show={visible} onHide={() => onVisibleChange(false)} size="lg">
      <Modal.Header
        closeButton={true}
        className="d-flex justify-content-between"
      >
        <Modal.Title className="flex-grow-1">
          Timesheet balance logs
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th>Old balance</th>
              <th>New balance</th>
              <th>Type</th>
              <th>Editor</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((l) => (
              <tr key={l.id}>
                <td>{formatMinutes(l.oldBalance)}</td>
                <td>{formatMinutes(l.newBalance)}</td>
                <td>{l.logType}</td>
                <td>{l.editor}</td>
                <td>{moment(l.createdAt).format('lll')}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeLogModal;
