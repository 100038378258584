import moment from 'moment';
import { FC, useState, useContext, useEffect } from 'react';
import { Modal, InputGroup, FormControl, Table } from 'react-bootstrap';
import userApi, { ActivityReturnType } from '../../../api/userApi';
import { AppContext } from '../../../context';
import { User } from '../../../types/User';
import {
  enumerateDaysForMonth,
  getAbsenceType,
  keyedPublicHolidays,
} from '../../../utils/absences';
import { formatMinutes, monthInputFormat } from '../../../utils/time';

interface Props {
  user: User;
  visible: boolean;
  onVisibleChange: (val: boolean) => void;
}

const ActivityModal: FC<Props> = ({ user, visible, onVisibleChange }) => {
  const context = useContext(AppContext);
  const [activeDate, updateActiveDate] = useState(moment().toDate());
  const [enumatedDates, setEnumatedDates] = useState(
    enumerateDaysForMonth(activeDate),
  );
  const [activity, setActivity] = useState<ActivityReturnType>({
    personalTimesheets: [],
    timesheets: [],
    absences: [],
  });

  useEffect(() => {
    const fetchTimesheets = async () => {
      try {
        const { data } = await userApi.getActivity(user.id, activeDate);

        setActivity(data);
      } catch {
        context.showToast({
          title: 'Fetch failed',
          message: 'Timesheet data fetch failed',
          variant: 'danger',
        });
      }
    };

    if (visible) {
      fetchTimesheets();
      setEnumatedDates(enumerateDaysForMonth(activeDate).reverse());
    }
  }, [activeDate, visible]);

  return (
    <Modal show={visible} onHide={() => onVisibleChange(false)} size="xl">
      <Modal.Header
        closeButton={true}
        className="d-flex justify-content-between"
      >
        <Modal.Title className="flex-grow-1">
          {user.firstName} Activity
        </Modal.Title>
        <InputGroup className="me-2" style={{ maxWidth: 200 }}>
          <FormControl
            type="month"
            value={moment(activeDate).format(monthInputFormat)}
            onChange={(e) => updateActiveDate(moment(e.target.value).toDate())}
          />
        </InputGroup>
      </Modal.Header>

      <Modal.Body>
        <Table striped responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>Timesheet</th>
              <th>Personal</th>
              <th>Absence</th>
            </tr>
          </thead>
          <tbody>
            {enumatedDates.map((d) => {
              const timesheet = activity.timesheets.filter((t) =>
                moment(t.startTime).isSame(d, 'date'),
              );
              const personalTimesheets = activity.personalTimesheets.filter(
                (t) => moment(t.startTime).isSame(d, 'date'),
              );
              const absence = activity.absences.find(
                (a) =>
                  moment(d).isBetween(a.startDate, a.endDate) ||
                  moment(d).isSame(a.startDate, 'date') ||
                  moment(d).isSame(a.endDate, 'date'),
              );
              const stuff = getAbsenceType(absence);
              return (
                <tr
                  key={d.toISOString()}
                  className={`${
                    keyedPublicHolidays[moment(d).format('YYYY-MM-DD')]
                      ? 'bg-danger'
                      : ''
                  }`}
                >
                  <td>{moment(d).format('dd D.M.YYYY')}</td>
                  <td>
                    {timesheet.map((t) => (
                      <>
                        {moment(t.startTime).format('HH:mm')} -{' '}
                        {!t.endTime ? '' : moment(t.endTime).format('HH:mm')}
                        {' / '}
                        {formatMinutes(t.minutes)}
                        {t.breakMinutes
                          ? ` - ${t.breakMinutes}min break`
                          : ''}{' '}
                        {!moment(t.createdAt).isSame(d, 'date')
                          ? `Created ${moment(t.createdAt).format(
                              'D.M.YYYY HH:mm',
                            )}`
                          : ''}
                        <br />
                      </>
                    ))}
                  </td>
                  <td>
                    {personalTimesheets.map((t) => (
                      <>
                        {moment(t.startTime).format('HH:mm')} -{' '}
                        {!t.endTime ? '' : moment(t.endTime).format('HH:mm')}
                        <br />
                      </>
                    ))}
                  </td>
                  <td>
                    {absence && (
                      <>
                        {absence.startDate} - {absence.endDate}
                        {' / '}
                        {stuff?.label}
                        {' / '}
                        {absence.status}
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default ActivityModal;
