import { Department } from '../types/Department';
import agent from './agent';

export interface CreateDepartmentData {
  title: string;
  companyId: string;
  divisions: {
    title: string;
    groupTitles: string;
  }[];
}

export interface CreateDivisionData {
  title: string;
  departmentId: string;
}
export interface CreateGroupData {
  title: string;
  divisionId: string;
}

const departmentApi = {
  createDepartment: (body: CreateDepartmentData) =>
    agent.post('/admin/department', {
      ...body,
      divisions: body.divisions.map((d) => ({
        ...d,
        groupTitles: d.groupTitles.split(','),
      })),
    }),
  createDivision: (body: CreateDivisionData) =>
    agent.post('/admin/division', body),
  createGroup: (body: CreateGroupData) => agent.post('/admin/group', body),
  getDepartments: (companyId: string) =>
    agent.get<{ data: Department[] }>(
      `/admin/company/${companyId}/departments`,
      {
        params: { perPage: 200 },
      },
    ),
};

export default departmentApi;
