import { AxiosResponse } from 'axios';
import agent from './agent';

export interface LoginBody {
  token: string;
  code: string;
}

export interface LoginResponse {
  token: string;
  email: string;
}

const authApi = {
  adminLogin: async (data: LoginBody): Promise<AxiosResponse<LoginResponse>> =>
    await agent.post('/login/admin', data),
  logout: async () => await agent.get('/logout'),
};

export default authApi;
