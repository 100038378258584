import { FC, useContext } from 'react';
import { useNavigate } from 'react-router';
import { Container, Card, Col } from 'react-bootstrap';
import authApi from '../api/authApi';
import { AppContext } from '../context';
import { setLoginData } from '../utils/localstorage';
import GoogleLoginButton from '../components/GoogleLoginButton';

const Login: FC = () => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <Container>
      <Col md={6} className="mx-auto mt-5">
        <Card className="d-flex align-items-center p-4">
          <div className="logo" style={{ marginBottom: '40px' }} />
          <GoogleLoginButton
            onLogin={async (resp) => {
              try {
                const { data } = await authApi.adminLogin({
                  token: resp.credential,
                  code: '9394',
                });
                setLoginData({ token: data.token, email: data.email });
                context.setToken(data.token);
                navigate('/', { replace: true });
              } catch {
                context.showToast({
                  title: 'Login failed',
                  message: 'Try again later',
                  variant: 'danger',
                });
              }
            }}
          />
        </Card>
      </Col>
    </Container>
  );
};

export default Login;
