import { FC } from 'react';
import { Nav } from 'react-bootstrap';
import { FaSignOutAlt, FaBuilding, FaUsers } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router';
import { logout } from '../utils/auth';

const SideBar: FC = () => {
  const location = useLocation();

  const navigate = useNavigate();

  return (
    <Nav
      onSelect={(key) => {
        navigate(key, { replace: true });
      }}
      activeKey={location.pathname}
      className="flex-column bg-light px-3 py-2 border-end shadow"
      style={{ height: '100vh', minWidth: '250px' }}
      variant="pills"
    >
      <span className="logo-sm mb-3" />
      <Nav.Item>
        <Nav.Link eventKey="/companies">
          <FaBuilding className="me-2" /> Companies
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="/users">
          <FaUsers className="me-2" /> Users
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          onClick={() => {
            logout();
          }}
        >
          <FaSignOutAlt className="me-2" /> Logout
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default SideBar;
