/* eslint-disable quotes */
import { FC } from 'react';
import { Department } from '../../../types/Department';
import _ from 'lodash';

interface Props {
  departments: Department[];
}

const MigrationQueryModal: FC<Props> = ({ departments }) => {
  const divs = _.flatMap(departments, (dep) => dep.divisions);
  const divUsers = _.flatMap(divs, (div) =>
    div.users.map((u) => ({
      ...u,
      divId: div.id,
      isManager: div.managerId === u.id,
    })),
  );
  const groups = _.flatMap(departments, (dep) =>
    _.flatMap(dep.divisions, (div) =>
      div.groups.map((g) => ({ ...g, departmentId: dep.id })),
    ),
  );
  const groupUsers = _.flatMap(groups, (group) =>
    group.users.map((u) => ({
      ...u,
      divId: group.id,
      isManager: group.managerId === u.id,
    })),
  );
  return (
    <div>
      Here is the query for new migration:
      <div className="p-1 bg-light mt-2" style={{ whiteSpace: 'pre-wrap' }}>
        <code>
          BEGIN;
          <br />
          {`INSERT INTO sub_department(id, title, "departmentId", "parentSubDepartmentId", "createdAt", "updatedAt") `}
          VALUES
          <br />
          {divs
            .map(
              (div) =>
                `('${div.id}', '${div.title}', '${div.departmentId}', null, '${div.createdAt}', '${div.updatedAt}')`,
            )
            .join(',\n')}
          ;
          <br />
          <br />
          <br />
          {`INSERT INTO sub_department(id, title, "departmentId", "parentSubDepartmentId", "createdAt", "updatedAt") `}
          VALUES
          <br />
          {groups
            .map(
              (group) =>
                `('${group.id}', '${group.title}', '${group.departmentId}', '${group.divisionId}', '${group.createdAt}', '${group.updatedAt}')`,
            )
            .join(',\n')}
          ;
          <br />
          <br />
          <br />
          {`INSERT INTO sub_department_user("userId", "subDepartmentId", "isManager", "createdAt", "updatedAt") `}
          VALUES
          <br />
          {divUsers
            .map(
              (user) =>
                `('${user.id}', '${user.divId}', ${user.isManager}, NOW(), NOW())`,
            )
            .join(',\n')}
          ; <br />
          <br />
          <br />
          {`INSERT INTO sub_department_user("userId", "subDepartmentId", "isManager", "createdAt", "updatedAt") `}
          VALUES
          <br />
          {groupUsers
            .map(
              (user) =>
                `('${user.id}', '${user.divId}', ${user.isManager}, NOW(), NOW())`,
            )
            .join(',\n')}
          ;
        </code>
      </div>
    </div>
  );
};

export default MigrationQueryModal;
