import { Competence } from './Competence';
import { Salary, User } from './User';

export enum UserChangeTypeId {
  EDIT_INFO = 1,
  ADD_COMPETENCE = 2,
  EDIT_COMPETENCE = 3,
  DELETE_COMPETENCE = 4,
  ADD_EQUIPMENT = 5,
  EDIT_EQUIPMENT = 6,
  DELETE_EQUIPMENT = 7,
  ADD_SALARY = 8,
  EDIT_SALARY = 9,
  DELETE_SALARY = 10,
  TIMESHEET_BALANCE_DIRECT_UPDATE = 11,
  TIMESHEET_BALANCE_AUTO_UPDATE = 12,
}

export type DiffKey = keyof User | keyof Competence | keyof Salary;

export interface UserChangeLog {
  id: string;
  userId: string;
  editor: string;
  typeId: UserChangeTypeId;
  type: UserChangeType;
  diff: Record<DiffKey, { __new: string | number; __old: string | number }>;
  createdAt: string;
  updatedAt: string;
}

export interface UserChangeType {
  id: UserChangeTypeId;
  titleFi: string;
  titleEn: string;
}

export interface TimesheetAuditLog {
  id: string;
  oldBalance: number;
  newBalance: number;
  userId: string;
  editor: string;
  logType:
    | 'timesheet-create'
    | 'timesheet-edit'
    | 'timesheet-delete'
    | 'auto-adjust'
    | 'balance-edit'
    | 'new-overtime-absence'
    | 'edit-overtime-absence'
    | 'delete-overtime-absence'
    | 'retroactive-absence'
    | 'delete-retroactive-absence';
  createdAt: string;
  updatedAt: string;
}
