import { Company } from '../types/Company';

export const testUserEmails = [
  'andrea.garcia+mock@example.example',
  'mikko.seppala+mock@example.example',
  'youssef.yaarafallah+mock@example.example',
  'minna.saarinen+mock@example.example',
  'adriana.gonzalo+mock@example.example',
  'sanni.sanninen+mock@example.example',
  'kimmo.saarinen+mock@example.example',
  'nomble.keita+mock@example.example',
  'brian.dankel+mock@example.example',
  'sirpa.kuustonen+mock@example.example',
  'miranda.stokes+mock@example.example',
  'saija.maijala+mock@example.example',
  'sam.rodgers+mock@example.example',
];

export const hasTestUsers = (company: Company) =>
  company.users.some((u) => testUserEmails.includes(u.email));

export const filterTestUsers = (company: Company) =>
  company.users.filter((u) => !testUserEmails.includes(u.email));
