import { FC, useContext, useEffect, useState } from 'react';
import { Button, Card, Modal, Table } from 'react-bootstrap';
import { Department } from '../../../types/Department';
import { AppContext } from '../../../context';
import departmentApi from '../../../api/departmentApi';
import Variable from '../../../utils/Variable';
import MigrationQueryModal from '../Modals/MigrationQueryModal';

interface Props {
  companyId: string;
}

const DepartmentsCard: FC<Props> = ({ companyId }) => {
  const { showToast } = useContext(AppContext);
  const [departments, setDepartments] = useState<Department[]>([]);

  useEffect(() => {
    const fetchDeps = async () => {
      try {
        const { data } = await departmentApi.getDepartments(companyId);
        setDepartments(data.data);
      } catch {
        showToast({
          variant: 'danger',
          title: 'Dep fetch failed',
          message: 'Fetch failed',
        });
      }
    };
    fetchDeps();
  }, [companyId]);
  return (
    <Card>
      <Card.Header className="d-flex justify-content-end">
        <Variable initialValue={false}>
          {(modalOpen, toggleModal) => (
            <>
              <Button onClick={() => toggleModal(true)} size="sm">
                Migration
              </Button>
              <Modal
                size="xl"
                show={modalOpen}
                onHide={() => toggleModal(false)}
              >
                <Modal.Header>
                  <Modal.Title>Organization migration query</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <MigrationQueryModal departments={departments} />
                </Modal.Body>
              </Modal>
            </>
          )}
        </Variable>
      </Card.Header>
      <Card.Body className="p-0">
        <Table>
          <thead>
            <tr>
              <th>Id </th>
              <th>Name</th>
              <th>Users</th>
            </tr>
          </thead>
          <tbody>
            {departments.map((dep) => {
              return (
                <tr key={dep.id}>
                  <td>{dep.id}</td>
                  <td>{dep.title}</td>
                  <td>{dep.users?.length}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default DepartmentsCard;
