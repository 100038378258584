import { debounce } from 'lodash';
import { FC, useContext, useState, useCallback } from 'react';
import {
  Container,
  Form,
  Col,
  InputGroup,
  ListGroup,
  Spinner,
  Row,
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import userApi from '../../api/userApi';
import { AppContext } from '../../context';
import { User } from '../../types/User';
import { formatUserName } from '../../utils/auth';
import UserCard from './SubComponents/UserCard';

const Users: FC = () => {
  const context = useContext(AppContext);
  const [users, updateUsers] = useState<User[]>([]);
  const [selectedUser, updateSelectedUser] = useState<User>(null);
  const [query, updateQuery] = useState('');
  const [loading, toggleLoading] = useState(false);

  const searchUsers = async (q?: string) => {
    try {
      toggleLoading(true);
      const { data } = await userApi.searchUsers(q || query);
      updateUsers(data);
      if (data.length) {
        updateSelectedUser(data[0]);
      }
      toggleLoading(false);
    } catch {
      toggleLoading(false);
      context.showToast({
        title: 'Fetch failed',
        message: 'User fetch failed',
        variant: 'danger',
      });
    }
  };

  const debounceSearch = useCallback(
    debounce((newValue) => searchUsers(newValue), 700),
    [],
  );

  return (
    <Container>
      <Row>
        <Col md={4} className="pt-3">
          <InputGroup>
            <InputGroup.Text>
              {loading ? (
                <Spinner size="sm" animation="border" />
              ) : (
                <FaSearch />
              )}
            </InputGroup.Text>
            <Form.Control
              value={query}
              onChange={(e) => {
                updateQuery(e.target.value);
                debounceSearch(e.target.value);
              }}
              placeholder="Search by user first/last name or id"
            />
          </InputGroup>

          <ListGroup
            onSelect={(k) => {
              const user: User = users.find((c) => c.id === k);
              updateSelectedUser(user);
            }}
            activeKey={selectedUser?.id}
            as="ul"
            style={{ maxHeight: 'calc(100vh - 60px) ' }}
            className="mt-2 overflow-scroll"
          >
            {users.map((u) => (
              <ListGroup.Item
                className="fs-6"
                action={true}
                eventKey={u.id}
                as="button"
                key={u.id}
              >
                <div>{formatUserName(u)}</div>
                <div>{u.company?.title}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>

        <Col md={8} className="pt-3">
          {selectedUser && (
            <UserCard
              selectedUser={selectedUser}
              updateSelectedUser={updateSelectedUser}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Users;
