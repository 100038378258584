import { ReactElement, useState } from 'react';

interface Props<InitialVal> {
  key?: string;
  initialValue: InitialVal;
  children: (
    val: InitialVal,
    updateVal: (val: InitialVal) => void,
  ) => ReactElement;
}

const Variable = <T extends unknown>({ initialValue, children }: Props<T>) => {
  const [val, updateVal] = useState(initialValue);

  return <>{children(val, updateVal)}</>;
};

export default Variable;
