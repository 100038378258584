import { FC, useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import _ from 'lodash';
import {
  Button,
  Form,
  Card,
  Row,
  Col,
  Modal,
  Table,
  Alert,
} from 'react-bootstrap';
import { LocalCompany } from '../Companies';
import { filterTestUsers, hasTestUsers } from '../../../utils/constants';
import { companyPermissions } from '../../../utils/permissions';
import { AppContext } from '../../../context';
import companyApi from '../../../api/companyApi';
import { formatUserName } from '../../../utils/auth';
import { roles } from '../../../utils/roles';
import { FaArchive, FaCheck } from 'react-icons/fa';

interface Props {
  selectedCompany: LocalCompany;
  updateSelectedCompany: (val: LocalCompany) => void;
  onSubmit: () => void;
}

interface CompanyForm {
  title: string;
  businessId: string;
  address: string;
  postalCode: string;
  city: string;
  addressCountry: string;
  paywallActive: boolean;
}

const CompanyCard: FC<Props> = ({
  selectedCompany,
  onSubmit,
  updateSelectedCompany,
}) => {
  const context = useContext(AppContext);
  const [updateLoading, toggleUpdateLoading] = useState(false);
  const [activityModalOpen, toggleActivityModal] = useState(false);

  const { setValue, register, handleSubmit } = useForm<{
    company: CompanyForm;
  }>();

  useEffect(() => {
    if (selectedCompany) {
      setValue(
        'company',
        _.pick(selectedCompany, [
          'title',
          'city',
          'address',
          'businessId',
          'addressCountry',
          'postalCode',
          'paywallActive',
        ]) as CompanyForm,
      );
    }
  }, [selectedCompany]);

  const submit = async (data: { company: CompanyForm }) => {
    try {
      toggleUpdateLoading(true);
      await companyApi.adminEdit({
        ..._.omit(selectedCompany, 'selectedPermissions'),
        ...data.company,
        // eslint-disable-next-line
        // @ts-ignore
        permissions: selectedCompany.selectedPermissions,
      });
      toggleUpdateLoading(false);
      context.showToast({
        title: 'Save success',
        message: 'Company info saved',
        variant: 'success',
      });
      onSubmit();
    } catch {
      toggleUpdateLoading(false);
      context.showToast({
        title: 'Save failed',
        message: 'Try again',
        variant: 'danger',
      });
    }
  };

  const allEmployees = filterTestUsers(selectedCompany);
  const activeEmployees = allEmployees.filter((e) => !e.archivedAt);
  const archivedEmployees = allEmployees.filter((e) => !!e.archivedAt);

  return (
    <Card>
      <Form onSubmit={handleSubmit(submit)}>
        <Card.Body>
          <div className="d-flex align-items-start justify-content-between">
            <div className="flex-column d-flex align-items-start">
              <span>Id: {selectedCompany.id}</span>
              <span>
                Created at:{' '}
                {moment(selectedCompany.createdAt).format('D.M.YYYY')}
              </span>
              <span>Country: {selectedCompany.country}</span>
              <span>
                {allEmployees.length} Total Profiles
                {` (${activeEmployees.length} Active,  ${archivedEmployees.length} Archived)`}
              </span>
              {hasTestUsers(selectedCompany) && (
                <span>⭐ Test data active</span>
              )}
            </div>
            <div className="d-flex flex-column">
              <Button
                type="button"
                disabled={updateLoading}
                variant="secondary"
                onClick={async () => {
                  try {
                    toggleUpdateLoading(true);
                    if (hasTestUsers(selectedCompany)) {
                      await companyApi.deleteMockData(selectedCompany.id);
                    }
                    await companyApi.generateMockData(selectedCompany.id);
                    onSubmit();
                    toggleUpdateLoading(false);
                    context.showToast({
                      title: 'Test data created',
                      message: 'New test data has been created',
                      variant: 'success',
                    });
                  } catch {
                    toggleUpdateLoading(false);
                    context.showToast({
                      title: 'Failed',
                      message: 'Try again',
                      variant: 'danger',
                    });
                  }
                }}
              >
                New test data
              </Button>

              {hasTestUsers(selectedCompany) && (
                <Button
                  type="button"
                  disabled={updateLoading}
                  className="mt-2"
                  variant="danger"
                  onClick={async () => {
                    try {
                      toggleUpdateLoading(true);
                      await companyApi.deleteMockData(selectedCompany.id);

                      onSubmit();
                      toggleUpdateLoading(false);
                      context.showToast({
                        title: 'Test data deleted',
                        message: 'Test data has been deleted ',
                        variant: 'success',
                      });
                    } catch {
                      toggleUpdateLoading(false);
                      context.showToast({
                        title: 'Failed',
                        message: 'Try again',
                        variant: 'danger',
                      });
                    }
                  }}
                >
                  Delete test data
                </Button>
              )}

              <Button
                type="button"
                className="mt-2"
                variant="info"
                onClick={() => {
                  toggleActivityModal(true);
                }}
              >
                Show user activity
              </Button>
            </div>
          </div>
          <Row className="mt-4">
            {selectedCompany?.stripeCustomerId ? (
              <Alert>
                This is a Stripe customer so paywall is handled from Stripe.
              </Alert>
            ) : (
              <Form.Group className="mb-3">
                <Form.Check
                  {...register('company.paywallActive')}
                  type="checkbox"
                  label="Paywall active"
                />
              </Form.Group>
            )}

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Company title</Form.Label>
                <Form.Control required {...register('company.title')} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Business id</Form.Label>
                <Form.Control {...register('company.businessId')} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Permissions</Form.Label>
                {companyPermissions.map((p) => {
                  const checked = selectedCompany.selectedPermissions.includes(
                    p.value,
                  )
                    ? true
                    : false;
                  return (
                    <Form.Check
                      disabled={!!selectedCompany?.stripeCustomerId}
                      key={p.value}
                      type="checkbox"
                      label={p.label}
                      checked={checked}
                      onChange={() => {
                        if (checked) {
                          updateSelectedCompany({
                            ...selectedCompany,
                            selectedPermissions:
                              selectedCompany.selectedPermissions.filter(
                                (per) => per !== p.value,
                              ),
                          });
                        } else {
                          updateSelectedCompany({
                            ...selectedCompany,
                            selectedPermissions: [
                              ...selectedCompany.selectedPermissions,
                              p.value,
                            ],
                          });
                        }
                      }}
                    />
                  );
                })}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control {...register('company.address')} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Postal code</Form.Label>
                <Form.Control {...register('company.postalCode')} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control {...register('company.city')} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Control {...register('company.addressCountry')} />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          <Button
            disabled={updateLoading}
            variant="danger"
            className="me-2"
            type="button"
            onClick={async () => {
              const answer = prompt(
                'Enter the name of the company to confirm delete',
              );
              if (
                answer.toLowerCase() !== selectedCompany.title.toLowerCase()
              ) {
                alert('Names did not match');
                return;
              }
              try {
                toggleUpdateLoading(true);
                await companyApi.delete(selectedCompany.id);
                onSubmit();
                toggleUpdateLoading(false);
                context.showToast({
                  title: 'Company deleted',
                  message: '',
                  variant: 'success',
                });
              } catch {
                toggleUpdateLoading(false);
                context.showToast({
                  title: 'Delete failed',
                  message: 'Try again',
                  variant: 'danger',
                });
              }
            }}
          >
            Delete
          </Button>
          <Button disabled={updateLoading} variant="primary" type="submit">
            Save
          </Button>
        </Card.Footer>
      </Form>
      <Modal
        size="lg"
        show={activityModalOpen}
        onHide={() => toggleActivityModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>User activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>User</th>
                <th>User email</th>
                <th>Role</th>
                <th>Last activity</th>
              </tr>
            </thead>
            <tbody>
              {allEmployees.map((e) => (
                <tr key={e.id}>
                  <td>
                    {e.archivedAt ? (
                      <FaArchive className="text-warning" />
                    ) : (
                      <FaCheck className="text-success" />
                    )}
                  </td>
                  <td>{formatUserName(e)}</td>
                  <td>{e.email}</td>
                  <td>{roles.find((r) => r.value === e.roleId)?.label}</td>
                  <td>
                    {e.lastActivity
                      ? moment(e.lastActivity.lastActivity).format('llll')
                      : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </Card>
  );
};

export default CompanyCard;
