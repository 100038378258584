import { Company } from '../types/Company';
import {
  CompanyPermissionId,
  EmployeePermissionId,
} from '../types/Permissions';
import { User } from '../types/User';
import { filterTestUsers } from './constants';

export const userCompanyHasPlus = (user?: User) =>
  user?.company?.permissions.some(
    (item) => item.id === CompanyPermissionId.CORE,
  );

export const userHasSettingsPermmission = (user?: User) =>
  user?.role?.permissions.some(
    (item) => item.id === EmployeePermissionId.COMPANY_SETTINGS,
  );

export const userHasDetailsPermmission = (user?: User) =>
  user?.role?.permissions.some(
    (item) => item.id === EmployeePermissionId.PERSONAL_SETTINGS,
  );

export const companyPermissions = [
  { label: 'Core', value: CompanyPermissionId.CORE },
  { label: 'Tracking', value: CompanyPermissionId.TIME_TRACKING },
  {
    label: 'Absence',
    value: CompanyPermissionId.ABSENCES,
  },
  {
    label: 'Projects',
    value: CompanyPermissionId.PROJECTS,
  },
  {
    label: 'Wellbeing',
    value: CompanyPermissionId.WELLBEING,
  },
  {
    label: 'Shifts',
    value: CompanyPermissionId.SHIFTS,
  },
];

const employeePrice = 7;
const modulePrice = 49;

export const calculatePrice = (company: Company) => {
  const employyes =
    employeePrice *
    filterTestUsers(company).filter((u) => !u.archivedAt).length;
  const modulePrices = modulePrice * (company.permissions.length - 1);

  return employyes + modulePrices;
};
