import { User } from '../types/User';

export enum Roles {
  ADMIN = 1,
  MANAGER = 2,
  EMPLOYEE = 3,
}

interface RoleItem {
  label: string;
  value: Roles;
}

export const roles: RoleItem[] = [
  { label: 'Admin', value: Roles.ADMIN },
  { label: 'Manager', value: Roles.MANAGER },
  { label: 'Employee', value: Roles.EMPLOYEE },
];

export const isMinManager = (user: User) =>
  [Roles.ADMIN, Roles.MANAGER].includes(user.roleId);

export const isAdmin = (user: User) => [Roles.ADMIN].includes(user.roleId);
