import { FC, useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import _ from 'lodash';
import { Button, Form, Card, Row, Col } from 'react-bootstrap';
import { AppContext } from '../../../context';
import ActivityModal from '../Modals/ActivityModal';
import TimesheetBalanceModal from '../Modals/TimesheetBalanceModal';
import { User } from '../../../types/User';
import userApi from '../../../api/userApi';
import { formatUserName } from '../../../utils/auth';
import ChangeLogModal from '../Modals/ChangeLogModal';

interface Props {
  selectedUser: User;
  updateSelectedUser: (val: User) => void;
}

export interface UserForm {
  timesheetBalance: number;
  weeklyWorkMinutes: number;
  holidaysPerMonth?: number;
  availableHolidays?: number;
  incrementedHolidays: number;
}

const UserCard: FC<Props> = ({ selectedUser, updateSelectedUser }) => {
  const context = useContext(AppContext);
  const [updateLoading, toggleUpdateLoading] = useState(false);
  const [balanceModalLoading, toggleBalanceModal] = useState(false);
  const [activityModalShowing, toggleActivityModal] = useState(false);
  const [changeLogModalShwoing, toggleChangeLogModalModal] = useState(false);

  const { setValue, register, handleSubmit } = useForm<{ user: UserForm }>();

  useEffect(() => {
    if (selectedUser) {
      setValue(
        'user',
        _.pick(selectedUser, [
          'timesheetBalance',
          'weeklyWorkMinutes',
          'holidaysPerMonth',
          'availableHolidays',
          'incrementedHolidays',
        ]) as UserForm,
      );
    }
  }, [selectedUser]);

  const submit = async (data: { user: UserForm }) => {
    try {
      toggleUpdateLoading(true);
      const formattedStuff = {};

      _.forEach(data.user, (val, key) =>
        _.assign(formattedStuff, { [key]: +val }),
      );

      const res = await userApi.adminEdit(
        selectedUser.id,
        formattedStuff as UserForm,
      );
      toggleUpdateLoading(false);
      context.showToast({
        title: 'Save success',
        message: 'User info saved',
        variant: 'success',
      });
      updateSelectedUser(res.data);
    } catch {
      toggleUpdateLoading(false);
      context.showToast({
        title: 'Save failed',
        message: 'Try again',
        variant: 'danger',
      });
    }
  };

  return (
    <Card>
      <Form onSubmit={handleSubmit(submit)}>
        <Card.Header>
          <Card.Title>
            {formatUserName(selectedUser)} | {selectedUser.company.title}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="flex-column d-flex align-items-start">
            {selectedUser.oauthSub && (
              <span>Social login: {selectedUser.oauthSub}</span>
            )}
            <span>Email: {selectedUser.email}</span>
            <span>Id: {selectedUser.id}</span>
            <span>
              Created at: {moment(selectedUser.createdAt).format('D.M.YYYY')}
            </span>
            <div className="mt-2">
              <Button
                onClick={() => toggleActivityModal(true)}
                size="sm"
                variant="secondary"
              >
                View activity
              </Button>
              <Button
                onClick={() => toggleBalanceModal(true)}
                size="sm"
                variant="info"
                className="ms-2"
              >
                Timesheet balance
              </Button>
              <Button
                onClick={() => toggleChangeLogModalModal(true)}
                size="sm"
                className="ms-2"
                variant="warning"
              >
                View audit log
              </Button>
            </div>
          </div>
          <Row className="mt-4">
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Timesheet balance (minutes)</Form.Label>
                <Form.Control {...register('user.timesheetBalance')} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Weekly work hours (minutes)</Form.Label>
                <Form.Control {...register('user.weeklyWorkMinutes')} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Incremented holidays</Form.Label>
                <Form.Control {...register('user.incrementedHolidays')} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Available holidays</Form.Label>
                <Form.Control {...register('user.availableHolidays')} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Holidays per month</Form.Label>
                <Form.Control {...register('user.holidaysPerMonth')} />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          <Button disabled={updateLoading} variant="primary" type="submit">
            Save
          </Button>
        </Card.Footer>
      </Form>
      <ActivityModal
        visible={activityModalShowing}
        user={selectedUser}
        onVisibleChange={toggleActivityModal}
      />
      <TimesheetBalanceModal
        visible={balanceModalLoading}
        user={selectedUser}
        onVisibleChange={toggleBalanceModal}
      />
      <ChangeLogModal
        visible={changeLogModalShwoing}
        user={selectedUser}
        onVisibleChange={toggleChangeLogModalModal}
      />
    </Card>
  );
};

export default UserCard;
