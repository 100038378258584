import moment from 'moment';
import { UserForm } from '../pages/Users/SubComponents/UserCard';
import { Absence } from '../types/Absence';
import { Timesheet, PersonalTimesheet } from '../types/Timesheet';
import { User } from '../types/User';
import { TimesheetAuditLog, UserChangeLog } from '../types/UserChangeLog';
import agent from './agent';

export interface ActivityReturnType {
  timesheets: Timesheet[];
  absences: Absence[];
  personalTimesheets: PersonalTimesheet[];
}

const userApi = {
  searchUsers: async (query: string) =>
    agent.get<User[]>('/users/search', { params: { query } }),
  adminEdit: async (id: string, body: UserForm) =>
    agent.put(`/users/${id}`, body),
  getTimesheetLogs: (id: string) =>
    agent.get<{ logs: TimesheetAuditLog[] }>(
      `/users/${id}/timesheet-balance-log`,
    ),
  getChangeLog: (id: string) =>
    agent.get<{ logs: UserChangeLog[] }>(`/users/${id}/change-log`),
  getActivity: (id: string, activeDate: Date) =>
    agent.get<ActivityReturnType>(`/users/${id}/activity`, {
      params: { activeDate: moment(activeDate).format('YYYY-MM-DD') },
    }),
};

export default userApi;
