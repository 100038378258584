import _ from 'lodash';
import moment from 'moment';
import { Absence, AbsenceStatus, AbsenceType } from '../types/Absence';

interface BasicItem {
  label: string;
}

interface StatusItem extends BasicItem {
  value: AbsenceStatus;
}

interface TypeItem extends BasicItem {
  value: AbsenceType;
}

export const absenceStatuses: StatusItem[] = [
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Descined',
    value: 'declined',
  },
];

export const absenceTypes: TypeItem[] = [
  {
    label: 'Holiday',
    value: 'holiday',
  },
  {
    label: 'Paid',
    value: 'paid',
  },
  {
    label: 'Unpaid',
    value: 'unpaid',
  },
  {
    label: 'Sick',
    value: 'sick',
  },
  {
    label: 'Parental',
    value: 'parental',
  },
  {
    label: 'Overtime',
    value: 'overtime',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const getAbsenceType = (absence: Absence) => {
  if (!absence) return null;
  const aType = absenceTypes.find((t) => t.value === absence.type);
  const status = absenceStatuses.find((s) => s.value === absence.status);

  const type =
    absence.customAbsenceType?.isHoliday || aType?.value === 'holiday'
      ? ('holiday' as const)
      : absence.customAbsenceType?.isOvertime || aType?.value === 'overtime'
      ? ('overtime' as const)
      : absence.customAbsenceType?.id === 'general' ||
        aType?.value === 'general'
      ? ('general' as const)
      : ('other' as const);

  const label = absence.customAbsenceType?.titleEn || aType?.label;

  return {
    label,
    type,
    status,
  };
};

export const publicHolidays = [
  '2021-05-13',
  '2021-06-26',
  '2021-11-06',
  '2021-12-06',
  '2021-12-25',
  '2021-12-26',
  '2022-01-01',
  '2022-01-06',
  '2022-04-15',
  '2022-04-17',
  '2022-04-18',
  '2022-05-01',
  '2022-05-26',
  '2022-06-05',
  '2022-06-24',
  '2022-06-25',
  '2022-11-05',
  '2022-12-06',
  '2022-12-24',
  '2022-12-25',
  '2022-12-26',
  '2023-01-01',
  '2023-01-06',
  '2023-04-07',
  '2023-04-08',
  '2023-04-09',
  '2023-04-10',
  '2023-05-01',
  '2023-05-18',
  '2023-05-28',
  '2023-06-23',
  '2023-06-24',
  '2023-11-04',
  '2023-12-06',
  '2023-12-24',
  '2023-12-25',
  '2023-12-26',
  '2024-01-01',
  '2024-01-06',
  '2024-03-29',
  '2024-03-31',
  '2024-04-01',
  '2024-05-01',
  '2024-05-09',
  '2024-05-19',
  '2024-06-21',
  '2024-06-22',
  '2024-11-02',
  '2024-12-06',
  '2024-12-24',
  '2024-12-25',
  '2024-12-26',
  '2025-01-01',
  '2025-01-06',
  '2025-04-18',
  '2025-04-20',
  '2025-04-21',
  '2025-05-01',
  '2025-05-29',
  '2025-06-08',
  '2025-06-20',
  '2025-06-21',
  '2025-11-01',
  '2025-12-06',
  '2025-12-24',
  '2025-12-25',
  '2025-12-26',
  '2026-01-01',
  '2026-01-06',
];

export const keyedPublicHolidays = _.keyBy(publicHolidays);

export const enumerateDaysForMonth = function (activeDate: Date) {
  const dates: Date[] = [];

  const currDate = moment(activeDate).startOf('month').subtract(1, 'day');
  const lastDate = moment(activeDate).endOf('month');

  while (currDate.add(1, 'day').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
};
