import { createContext } from 'react';

export interface ToastInfo {
  title: string;
  message: string;
  variant:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light';
}

export interface AppContext {
  token: string | null;
  setToken: (token: string | null) => void;
  toastInfo: ToastInfo | undefined;
  showToast: (val: ToastInfo) => void;
}

export const AppContext = createContext<AppContext>({} as any);
