import authApi from '../api/authApi';
import { User } from '../types/User';
import workantStorage from './localstorage';

export const logout = async () => {
  workantStorage.removeItem('localUser');
  workantStorage.removeItem('loginData');
  try {
    await authApi.logout();
  } catch {
    // eslint-disable-next-line no-console
    console.log('Logout failed');
  }
  window.location.reload();
};

export const formatUserName = (user?: User) =>
  `${user?.firstName || 'No'} ${user?.lastName || 'Name'}`;
