export type StorageKey = 'loginData' | 'localUser';

const workantStorage = {
  getItem: <T>(key: StorageKey): T => {
    const item = localStorage.getItem(key);
    return JSON.parse(item);
  },
  setItem: (key: StorageKey, value: any) =>
    localStorage.setItem(key, JSON.stringify(value)),
  removeItem: (key: StorageKey) => localStorage.removeItem(key),
  clear: () => localStorage.clear(),
};

export const setLoginData = ({
  token,
  email,
}: {
  token: string;
  email: string;
}) =>
  workantStorage.setItem('loginData', {
    token,
    email,
  });

export interface LoginData {
  token: string;
  email: string;
}

export const getLoginData = (): LoginData => {
  const data = workantStorage.getItem<LoginData>('loginData');
  return data;
};

export default workantStorage;
