import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Toast, ToastContainer } from 'react-bootstrap';
import { AppContext, ToastInfo } from './context';
import { getLoginData } from './utils/localstorage';
import Login from './pages/Login';
import Companies from './pages/Companies/Companies';
import SideBar from './components/SideBar';
import Users from './pages/Users/Users';

const App = () => {
  const [appContext, updateAppContext] = useState<
    Pick<AppContext, 'token' | 'toastInfo'>
  >({
    token: getLoginData()?.token,
    toastInfo: undefined,
  });

  const showToast = (val: ToastInfo) =>
    updateAppContext((ps) => ({
      ...ps,
      toastInfo: val,
    }));

  const setToken = (val: string | null) =>
    updateAppContext((ps) => ({
      ...ps,
      token: val,
    }));

  const isLoggedIn = !!appContext.token;

  const closeToast = () => {
    updateAppContext((ps: AppContext) => ({
      ...ps,
      toastInfo: undefined,
    }));
  };

  return (
    <AppContext.Provider value={{ ...appContext, showToast, setToken }}>
      <BrowserRouter>
        <div className="d-flex">
          {isLoggedIn && <SideBar />}
          <Routes>
            {!isLoggedIn && <Route path="/login" element={<Login />} />}
            {isLoggedIn && <Route path="/companies" element={<Companies />} />}
            {isLoggedIn && <Route path="/users" element={<Users />} />}
            {isLoggedIn && (
              <Route path="*" element={<Navigate to="/companies" replace />} />
            )}
            {!isLoggedIn && (
              <Route path="*" element={<Navigate to="/login" replace />} />
            )}
          </Routes>
        </div>
      </BrowserRouter>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          show={!!appContext.toastInfo}
          animation={true}
          bg={appContext.toastInfo?.variant}
          onClose={closeToast}
          delay={5000}
          autohide={true}
        >
          <Toast.Header>
            <strong className="me-auto">{appContext.toastInfo?.title}</strong>
          </Toast.Header>
          <Toast.Body>{appContext.toastInfo?.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </AppContext.Provider>
  );
};

export default App;
